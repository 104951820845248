img {
    position: fixed;
    width: 30vw;
    height: 30vh;
    z-index: -2;
}

/* Phones and portrait-mode tablets */
@media only screen and (max-width: 999px) {
    .desktop {
        display: none;
    }
}
