.social-see-more,
.social-icon {
    position: relative;
    color: #333;
}

.social-see-more {
    font-family: 'Proxima Nova Rg';
    font-weight: bold;
    font-size: 14pt;
    width: 100%;
    margin: 50px 0 10px 0;
    text-align: center;
}

.social-icon {
    display: inline-block;
    height: 64px;
    width: 64px;
    left: calc(50% - 32px);
    cursor: pointer;
}

svg:hover {
    transition: fill 0.3s;
}

.github svg:hover { fill: #aaa }

.linkedin svg:hover { fill: #0a66c2 }
