:root {
    --icon-size: 64px;
    --padding: 10px;
    --accent-color: #62c1b1;
}

#experience-container {
    max-height: 80vh;
    padding: var(--padding);
    background: transparent;
    overflow-y: auto;
    overflow-x: hidden;
}

.separator-line-experience {
    position: relative;
    width: 90%;
    height: 2px;
    left: 5%;
    margin: 40px 0px;
    border-top: 1px solid var(--accent-color);
    border-bottom: 1px solid var(--accent-color);
}

/* Desktop / landscape-mode tablets */
@media only screen and (min-width: 1000px) {
    #experience-container {
        width: 32vw;
    }
}

/* Phones and portrait-mode tablets */
@media only screen and (max-width: 999px) {
    #experience-container {
        display: none;  /* TEMP */
        max-width: 100%;
        margin-bottom: calc(var(--icon-size) + 2 * var(--padding));
    }
}
