:root {
    --padding: 10px;
    --accent-color: #62c1b1;
}

.experience-label,
.experience-list-wrapper {
    list-style: none;
}

.experience-label {
    font-family: 'Proxima Nova Rg';
    text-align: center;
}

.experience-cell-container::before {
    content: '';
    background-color: rgb(255, 255, 255);
    position: absolute;
    left: -10px;
    top: 10px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 3px solid var(--accent-color);
    z-index: 60;
    transition: background-color 0.2s;
}

.experience-cell-container {
    position: relative;
    padding-left: 24px;
    padding-right: 20px;
}

.experience-cell-container:not(:last-of-type)::after {
    width: 3px;
    height: calc(100% + 10px);
    content: '';
    position: absolute;
    left: -2px;
    top: 28px;
    z-index: 2;
    background-color: var(--accent-color);
}

.employer-label,
.experience-date-label, 
.experience-city-label, 
.experience-role-label,
.experience-summary-label {
    position: relative;
    font-family: 'Proxima Nova Rg';
    left: 10px;
}

.employer-label {
    position: relative;
    font-size: 20pt;
    display: inline;
    top: 0px;
}

.experience-sep-pipe {
    position: relative;
    display: inline-block;
    content: '';
    width: 2px;
    height: 28px;
    top: 6px;
    left: 12px;
    margin: 0px 5px;
    background-color: var(--accent-color);
}

.experience-location-container {
    position: relative;
}

.experience-role-label {
    font-size: 16pt;
    margin: 0px 0px 5px 0px;
}

.experience-summary-label {
    margin: 0px 0px 20px 0px;
    font-size: 18px;
}

/* Desktop / landscape-mode tablets */
@media only screen and (min-width: 1000px) {
    .experience-cell-container:hover::before {
        background-color: var(--accent-color);
    }

    .experience-date-label, 
    .experience-city-label {
        display: inline;
        font-size: 14pt;
        margin: 5px;
    }
}


/* Phones and portrait-mode tablets */
@media only screen and (max-width: 999px) {
    .experience-label {
        top: 10px;
        text-align: center;
    }

    .experience-sep-pipe {
        display: none;
    }

    .experience-date-label, 
    .experience-city-label {
        font-size: 14pt;
        margin: 5px 0px;
    }
}
