:root {
    --padding: 10px;
    --accent-color: #62c1b1;
}

#about-header,
#about-p-wrapper {
    position: absolute;
    height: max-content;
    width: 40vw;
    z-index: -1;
}

#about-header,
.about-p {
    font-family: 'Proxima Nova Rg';
    color: #333;
}

#about-header {
    top: 0;
    left: 30vw;
    font-size: 40pt;
    text-align: center;
    margin: var(--padding) 0;
}

#about-p-wrapper {
    top: 80px;
    left: calc(30vw - var(--padding));
    padding: var(--padding);
}

.about-p {
    font-size: 18pt;
    text-indent: 2.5em;
}

/* Phones and portrait-mode tablets */
@media only screen and (max-width: 999px) {
    #about-header,
    #about-p-wrapper {
        left: 0;
        width: 100vw;
    }

    .about-p {
        font-size: 14pt;
    }
}
