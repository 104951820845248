:root {
    --icon-size: 64px;
    --paddle-ring-scale: 1.3;
    --padding: 10px;
    --container-height: 30vh;
    --foreground: #333;
    --background: white;
    --accent-color: #62c1b1;
    --accent-color-muted: #62c1b17f;
    --outline-color: #7f7f7f;
}

#page-menu-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
}

/* Desktop / landscape-mode tablets */
@media only screen and (min-width: 1000px) {
    #page-menu-wrapper {
        display: none;
    }

    #page-menu-list {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: var(--container-height);
        width: max-content;
        top: calc(50vh - var(--container-height) / 2);
        left: 0px;

        list-style: none;
    }

    .page-menu-list-item::before {
        content: "";
        position: absolute;
        width: 44px;
        height: calc(var(--icon-size) / 4);
    
        left: -44px;
        top: calc(var(--icon-size) * var(--paddle-ring-scale) * 3 / 8);
        border-top: 3px solid var(--outline-color);
        border-bottom: 3px solid var(--outline-color);
    }

    .page-menu-list-item {
        position: relative;
        fill: var(--outline-color);
        margin: calc(var(--padding) / 2) 0px;
        left: 20px;
    
        width: calc(var(--icon-size) * var(--paddle-ring-scale));
        height: calc(var(--icon-size) * var(--paddle-ring-scale));
        border: 4px solid var(--outline-color);
        border-radius: calc(var(--icon-size) * var(--paddle-ring-scale));
    }

    .current {
        background-color: var(--accent-color-muted);
    }

    .page-menu-list-item:hover {
        transition: background-color 0.2s;
        background-color: var(--accent-color);
    }

    .page-menu-link {
        position: relative;
        top: calc(50% - var(--icon-size) / 2);
        left: calc(var(--icon-size) * (var(--paddle-ring-scale) - 1) / 2 - 1px);
    }
    
    .page-menu-link svg {
        position: relative;
        width: var(--icon-size);
        height: var(--icon-size);
    }

    .paddle-label {
        display: none;
    }
}

/* Phones and portrait-mode tablets */
@media only screen and (max-width: 999px) {
    :root {
        --bar-width: 64px;
        --bar-height: 10px;
        --hamburger-gap: 10px;
        --hamburger-margin: 10px;
        --animation-timing: 0.2s ease-in-out;
        --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
    }
    
    *,
    *::after,
    *::before {
        box-sizing: border-box;
    }
    
    body {
        margin: 0;
    }

    #page-menu-wrapper {
        --x-width: calc(var(--hamburger-height) * 1.41421356237);

        display: flex;
        flex-direction: column;
        gap: var(--hamburger-gap);
        width: max-content;
        position: absolute;
        bottom: var(--hamburger-margin);
        left: var(--hamburger-margin);
        z-index: 10;
        cursor: pointer;
    }

    .checked {
        --foreground: var(--accent-color);
        --background: #333;
    }

    .checked::before, .unchecked::before,
    .checked::after, .unchecked::after,
    #page-menu-wrapper input {
        content: "";
        width: var(--bar-width);
        height: var(--bar-height);
        background-color: var(--foreground);
        border-radius: 9999px;
        transform-origin: left center;
        -moz-transform-origin: left center;
        transition: opacity var(--animation-timing), width var(--animation-timing), 
            rotate var(--animation-timing), translate var(--animation-timing),
            background-color var(--animation-timing);
        -moz-transition: opacity var(--animation-timing), width var(--animation-timing), 
            rotate var(--animation-timing), translate var(--animation-timing),
            background-color var(--animation-timing);
    }

    #page-menu-wrapper input {
        appearance: none;
        padding: 0;
        margin: 0;
        outline: none;
        pointer-events: none;
    }

    .checked::before {
        rotate: 45deg;
        width: var(--x-width);
        translate: 0 calc(var(--bar-height) / -2);
    }

    .checked::after {
        rotate: -45deg;
        width: var(--x-width);
        translate: 0 calc(var(--bar-height) / 2);
    }

    #page-menu-wrapper input:checked {
        opacity: 0;
        width: 0;
    }

    #page-menu-list {
        transition: translate var(--animation-timing);
        -moz-transition: translate var(--animation-timing);
        translate: -100%;
        padding: 0;
        background-color: var(--foreground);
        color: var(--background);
        max-width: 100vw;
        min-height: 100vh;
    }

    #page-menu-list nav {
        position: absolute;
        bottom: calc(var(--hamburger-height) + var(--hamburger-margin) + 1rem);
    }

    .checked + #page-menu-list {
        translate: 0;
    }

    .page-menu-list-item {
        margin-bottom: 10px;
    }

    .page-menu-link {
        position: relative;
        top: calc(50% - var(--icon-size) / 2);
        left: calc(var(--hamburger-margin) / 2);
        text-decoration: none;
    }
    
    .page-menu-link svg {
        display: none;
    }

    .paddle-label::before {
        content: "> ";
    }

    .paddle-label {
        color: white;
        font-family: "Proxima Nova Rg";
        font-size: 40pt;
        cursor: pointer;
    }

    .current .paddle-label {
        color: #bbb;
        cursor: default;
    }
}
