:root {
    --icon-size: 64px;
    --padding: 20px;
}

#socialsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

#issueReporterWrapper {
    position: fixed;
}

/* Desktop / landscape-mode tablets */
@media only screen and (min-width: 1000px) {
    #bodySections {
        position: absolute;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: transparent;

        width: 90vw;
        height: 90vh;
        left: 5vw;
        top: 5vh;
    }

    #socialsContainer { 
        position: absolute;

        width: calc(2 * var(--icon-size) + var(--padding));
        height: var(--icon-size);
        left: calc(50vw - calc(2 * var(--icon-size) + var(--padding)) / 2);
        bottom: 5vh;
    }

    #issueReporterWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        text-align: center;
        width: 40vw;
        bottom: 1em;
        left: 30vw;
    }
}

/* Phones and portrait-mode tablets */
@media only screen and (max-width: 999px) {
    #bodySections {
        position: relative;
        background: transparent;

        width: calc(100vw - 2 * var(--padding));
        left: var(--padding);
        top: var(--padding);
    }

    #socialsContainer { 
        position: fixed;

        width: 100%;
        height: calc(var(--icon-size) + 2 * var(--padding));
        left: 0px;
        bottom: 0px;
        background-color: #ffffff7f;
        z-index: -1;
        backdrop-filter: blur(20px);
    }

    #issueReporterWrapper {
        text-align: right;
        width: 13em;
        bottom: 1.5em;
        right: var(--padding);
        z-index: 100;
    }

    #issueReporterLink {
        font-size: 14pt;
    }
}
