:root {
    --padding: 10px;
}

#bio-container {
    max-height: 80vh;
    padding: var(--padding);
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.4);
    overflow-x: hidden;
    overflow-y: auto;
}

#bio-container h1, 
#bio-container h2, 
#bio-container p {
    position: relative;
    color: #404040;
    margin: 10px;
    font-family: 'Proxima Nova Rg';
}

#bio-container h1, 
#bio-container h2 {
    top: var(--padding);
    left: var(--padding);
}

#bio-container h1 {
    font-size: 30pt;
    color: #2f2f2f;
}

.separator-line-bio {
    position: relative;
    width: 100%;
    height: 2px;
    margin: 40px 0px 30px 0px;
    border-top: 1px solid var(--accent-color);
    border-bottom: 1px solid var(--accent-color);
}

#bio-container p {
    text-indent: 30px;
    font-size: 18pt;
    margin-bottom: var(--padding);
}

#bio-container ol {
    position: relative;
    left: -40px;
}

.info-option-row {
    list-style-type: none;
    margin-bottom: 5px;
}

.info-option-row svg {
    margin: 5px var(--padding) 0px 10px;
}

.info-option-row .bio-link {
    position: relative;
    top: -6px;
    font-family: 'Proxima Nova Rg';
    font-size: 14pt;
    text-decoration: none;
}

/* Desktop / landscape-mode tablets */
@media only screen and (min-width: 1000px) {
    #bio-container {
        width: 32vw;
    }
}

/* Phones and portrait-mode tablets */
@media only screen and (max-width: 999px) {
    #bio-container {
        position: absolute;
        top: 0;
        max-height: 75vh;
        max-width: 100%;
    }
    
    #bio-container h1 {
        margin: 0;
        text-align: center;
        width: calc(100% - 2 * var(--padding));
    }
    
    .separator-line-bio {
        margin-top: 30px;
    }

    #bio-container p {
        font-size: 15pt;
    }
}
