:root {
    --padding: 10px;
    --accent-color: #62c1b1;
}

#portfolio-header,
#portfolio-body-wrapper {
    position: absolute;
    left: 0;
    height: max-content;
    font-family: 'Proxima Nova Rg';
    z-index: -1;
}

#portfolio-header {
    top: 0;
    width: 100vw;
    font-size: 40pt;
    color: #333;
    text-align: center;
    margin: var(--padding) 0;
}

#portfolio-body-wrapper {
    top: 80px;
    width: calc(100vw - 2 * var(--padding));
    padding: var(--padding);
}

#portfolio-body-wrapper p {
    font-size: 14pt;
    text-align: center;
}

#portfolio-data-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: flex-start;
    gap: 10px;
    
    top: 28px;
    left: 25vw;
    width: calc(50vw - 2 * var(--padding));

    /* border: 2px dashed red; */
}

/* Phones and portrait-mode tablets */
@media only screen and (max-width: 999px) {
    #portfolio-body-wrapper {
        top: 80px;
        width: 100vw;
        padding: var(--padding);
    }
    
    #portfolio-data-wrapper {
        left: 0;
        width: 100%;
    }
}
